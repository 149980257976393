import React from 'react'

function DislikeIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30px"
			height="30px"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M19 5L4.99998 19M5.00001 5L19 19"
				stroke="#FFFFFF"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default DislikeIcon
